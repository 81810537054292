import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import qpcore from "./qpcore.png";
import tgIcon from "./tg.png";
import ytIcon from "./YT.png";
import igIcon from "./IG.png";
import backIcon from './back_icn.png';

const TermsConditionsContent = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    let scrollStep = 1;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (content.scrollTop + content.clientHeight >= content.scrollHeight) {
          clearInterval(scrollInterval);
        } else {
          content.scrollTop += scrollStep;
        }
      }, 50);
    };

    startAutoScroll();

    const stopAutoScroll = () => {
      clearInterval(scrollInterval);
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
    };

    content.addEventListener('wheel', stopAutoScroll);
    content.addEventListener('touchstart', stopAutoScroll);

    return () => {
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="legal-content-container">
      <div className="legal-content" ref={contentRef}>
	<h1>Terms & Conditions</h1>
       <p><strong>Effective Date:</strong> December 19, 2024</p>
       <h2>Introduction</h2>
       <p>
         Welcome to Quality Performance Core (represented by Oleksandr Trishkin) ("we," "us," or "our"). By accessing or using our application, you agree to these Terms & Conditions ("Terms"). Please read them carefully.
       </p>
       <h2>Use of the Application</h2>
       <p>Our platform includes:</p>
          <p><strong>Quality Performance Core Beats Platform:</strong> A marketplace for unlimited licensing services for instrumental tracks (beats), empowering creators with the tools to craft their next hit. Operated by Quality Performance Core, represented by Oleksandr Trishkin.</p>
          <p><strong>Next-Generation Distributor:</strong> A cutting-edge distribution platform designed to redefine how music reaches audiences. Launching in Q2 2025.</p>
          <p><strong>qpCore Artist Mini-Game:</strong> A Telegram-based game where users tap coins to earn rewards within the qpCore ecosystem. ("qpCore" refers to Quality Performance Core.)</p>
       <h2>User Responsibilities</h2>
       <p>
         <strong>Compliance:</strong> You agree to use the application in accordance with all applicable laws and regulations.
       </p>
       <p>
       <strong>Data Security:</strong> You are responsible for maintaining the confidentiality of your data information.
       </p>
       <h2>Intellectual Property</h2>
       <p>
         All content and materials are owned by Quality Performance Core or its licensors and are protected by intellectual property laws. Unauthorized use is prohibited.
       </p>
       <h2>Limitation of Liability</h2>
       <p>
         Quality Performance Core is not liable for any indirect, incidental, or consequential damages arising from your use of the application.
       </p>
       <h2>Changes to Terms</h2>
       <p>
         We may update these Terms periodically. Changes will be effective upon posting to this page.
       </p>
       <h2>Contact Us</h2>
       <p>
         If you have any questions, please contact us at contact@qpcore.com.
       </p>
      </div>
    </div>
  );
};

const PrivacyPolicyContent = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    let scrollStep = 1;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (content.scrollTop + content.clientHeight >= content.scrollHeight) {
          clearInterval(scrollInterval);
        } else {
          content.scrollTop += scrollStep;
        }
      }, 50);
    };

    startAutoScroll();

    const stopAutoScroll = () => {
      clearInterval(scrollInterval);
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
    };

    content.addEventListener('wheel', stopAutoScroll);
    content.addEventListener('touchstart', stopAutoScroll);

    return () => {
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="legal-content-container">
      <div className="legal-content" ref={contentRef}>
         <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> December 19, 2024</p>
        <h2>Introduction</h2>
	<p>
	  At Quality Performance Core (represented by Oleksandr Trishkin) ("we," "us," or "our"), we respect your privacy. This Privacy Policy explains how we collect, use, and protect your information across our resources, which include a beat licensing platform, a Telegram-based mini-game, and future distribution services.
	</p>
	<h2>Information We Collect</h2>
	<p>Our platform collects minimal data based on the specific services you use:</p>
	   <p><strong>Telegram Game Data:</strong> For users of our Telegram mini-game, we collect statistics on user activity and reward progress. This information is anonymized and used solely for maintaining game functionality and providing rewards.</p>
	   <p><strong>Tracking Pixels:</strong> To improve our marketing efforts, we use Facebook and TikTok tracking pixels to gather aggregated, non-personal interaction data. No personal information is collected.</p>
	   <p><strong>Future Distribution Services:</strong> Our upcoming distribution platform (launching in Q2 2025) will adhere to similar minimal data collection principles, focusing on aggregated usage data for service optimization.</p>
	<h2>How We Use Your Information</h2>
	<p>
	  <strong>To Provide Services:</strong> Ensuring functionality and access across our resources, including tracking rewards in the Telegram game and optimizing our beat platform and future distribution services.
	</p>
	<p>
	  <strong>To Improve Services:</strong> Analyzing anonymized usage data to enhance user experience across all platforms.
	</p>
	<p>
	  <strong>Minimal Communication:</strong> We do not require email addresses or other direct contact details. Any important updates or notifications will be provided directly within the relevant platform interface.
	</p>
	<h2>Information Sharing</h2>
	<p>
	  We do not sell, rent, or trade personal information. Any shared information is non-personally identifiable and used only with trusted service providers to operate and maintain our platforms.
	</p>
	<h2>Data Security</h2>
	<p>
	  We implement reasonable security measures to protect any minimal data collected. As we avoid handling sensitive personal information such as email addresses or payment details, data security risks are inherently minimized.
	</p>
	<h2>Children's Privacy</h2>
	<p>
	  Our platform is not intended for users under 13 years of age. We do not knowingly collect personal information from children.
	</p>
	<h2>Changes to This Policy</h2>
	<p>
	  We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date, and such changes become effective immediately upon posting.
	</p>
	<h2>Contact Us</h2>
	<p>
	  For questions or concerns regarding this Privacy Policy, please contact us at contact@qpcore.com.
	</p>
      </div>
    </div>
  );
}

function App() {
  const [currentStep, setCurrentStep] = useState(0);

  const sendEvent = async (eventName) => {
    try {
      const response = await fetch('/api/track-event', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          eventName: eventName
        })
      });

      const data = await response.json();
      if (data.success) {
        console.log(`Подія "${eventName}" успішно відправлено.`);
      } else {
        console.error(`Помилка при відправці події "${eventName}":`, data.error);
      }
    } catch (error) {
      console.error(`Помилка при відправці події "${eventName}":`, error);
    }
  };

  const handleLinkClick = (event, eventName, url) => {
    event.preventDefault();

  if(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

    sendEvent(eventName).then(() => {
      if (newWindow) {
        newWindow.focus();
      }
    }).catch(() => {
      if (newWindow) {
        newWindow.focus();
      }
    });
  } else { 
    sendEvent(eventName).catch(() => {
    });
   }
  };

  const steps = [
    {
      id: 'welcome',
      title: "Welcome to",
      subtitle: "Quality Performance Core",
      description: "Social & Services",
      content: (
        <div className="button-container">
	  <a
            href="https://beats.qpcore.com"
            className="button"
            onClick={(e) => handleLinkClick(e, 'Beats Platform', 'https://beats.qpcore.com')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={qpcore} alt="Beats" className="button-icon" />
              </div>
              <span className="button-text">Beats Platform (Join Today)</span>
            </div>
          </a>
	  <button
            className="button"
            onClick={(e) => handleLinkClick(e, 'Distribution Platform', '')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={qpcore} alt="Distribution" className="button-icon" />
              </div>
              <span className="button-text">Distribution Platform (2Q 2025)</span>
            </div>
          </button>
	  <a
            href="https://t.me/qpcore_bot/game/"
            className="button"
            onClick={(e) => handleLinkClick(e, 'Telegram Game', 'https://t.me/qpcore_bot/game/')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={tgIcon} alt="Telegram" className="button-icon" />
              </div>
              <span className="button-text">Telegram Game</span>
            </div>
          </a>
	  <a
            href="https://www.youtube.com/@qpcore"
            className="button"
            onClick={(e) => handleLinkClick(e, 'YouTube Channel', 'https://www.youtube.com/@qpcore')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={ytIcon} alt="YouTube" className="button-icon" />
              </div>
              <span className="button-text">YouTube Channel</span>
            </div>
          </a>
          <a
            href="https://www.instagram.com/beats.qpcore/"
            className="button"
            onClick={(e) => handleLinkClick(e, 'Instagram Beats Profile', 'https://www.instagram.com/beats.qpcore/')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={igIcon} alt="Instagram" className="button-icon" />
              </div>
              <span className="button-text">Instagram Beats Profile</span>
            </div>
          </a>
        </div>
      )
    },
    {
      id: 'privacy-policy',
      title: "",
      subtitle: "",
      description: "",
      content: <PrivacyPolicyContent />
    },
    {
      id: 'terms-conditions',
      title: "",
      subtitle: "",
      description: "",
      content: <TermsConditionsContent />
    }	  
  ];

  const handleFooterLinkClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const handleBackClick = () => {
    if (currentStep !== 0) {
      setCurrentStep(0);
    }
  };

  const renderSteps = () => {
    const step = steps[currentStep];

    return (
      <div
        key={currentStep}
        className={`content visible`}
        id={`step-${currentStep}`}
      >
        {currentStep === 0 && (
          <div className="coin-container">
            <img src={qpcore} alt="qp Core" className="coin" />
          </div>
        )}
        {step.title && <h1 className="slide-down">{step.title}</h1>}
        {step.subtitle && <h2 className="slide-down">{step.subtitle}</h2>}
        {step.description && <p className="slide-up">{step.description}</p>}
        {step.content}
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        {renderSteps()}
        {currentStep !== 0 && (
          <img
            src={backIcon}
            alt="Back"
            className="back-icon visible"
            onClick={handleBackClick}
          />
        )}
      </header>
      <footer className="footer">
        <div className="footer-content slide-up">
          <button
            className="footer-link"
            onClick={() => handleFooterLinkClick(1)}
          >
            Privacy
          </button>
          <span className="footer-divider">|</span>
          <button
            className="footer-link"
            onClick={() => handleFooterLinkClick(2)}
          >
            Terms
          </button>
          <span className="footer-divider">|</span>
          <span className="footer-copy">© qpCore 2025</span>
        </div>
      </footer>
    </div>
  );
}

export default App;

